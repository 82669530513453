<template>
  <div v-else class="pa-0 ma-0">
    <TarjetaDatos
      class="pa-3"
      header
      titulo="Detalles"
      icon="eye"
      :datos="[
        { nombre: 'Tipo', valor: cont.tipo },
        {
          nombre: 'Fecha cont',
          valor: parseDate(cont.fechaContacto, false, true, true),
        },
        {
          nombre: 'Fecha registro',
          valor: parseDate(cont.fechaRegistro, false, true, true),
        },
        { nombre: 'Respondida', valor: textoResp },
        { nombre: 'Resumen', valor: cont.resumen },
      ]"
    />
  </div>
</template>

<script>
import { parseDate } from "@/utils/index.js";

export default {
  components: {
    StatusChip: () => import("@/components/StatusChip.vue"),
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
  },
  props: {
    contacto: Object,
    idEstudio: String | Number,
  },
  data() {
    return {
      cont: { ...this.contacto },
    };
  },
  computed: {
    textoResp() {
      switch (this.cont.respondida) {
        case null:
        case undefined:
          return "-";
        case true:
          return "Sí";
        case false:
          return "No";
        default:
          return "-";
      }
    },
  },
  methods: {
    parseDate,
  },
};
</script>
